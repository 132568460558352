<template>
  <div class="body" style="direction: rtl;">
    <v-row>
      <v-col cols="12" md="6">
        <div class="mt-10 pa-10 text-center">
            <div class="errorMesg" v-if="showerr">اسم المستخدم او كلمة المرور غير صحيحة يرجى المحاولة مره اخرى</div>
            <div class="mt-16" :style="`border-radius:10px;color:#FFF;background:rgb(0, 0, 0);margin:auto;direction: `+this.$parent.lang.dir+`;width:300px;border:1px solid #000`">
              <div class="text-start pa-3">
                <label for="input-live">{{$parent.lang.login_name}}</label>
                  <b-form-input
                    id="username"
                    v-model="username"
                    @keyup.enter="enterlogin()"
                    aria-describedby="input-live-help input-live-feedback"
                    :placeholder="$parent.lang.login_name"
                    @keyup.native.enter="doLogin()"
                    trim
                  ></b-form-input>
                  <b-form-invalid-feedback v-if="!nameState" id="input-live-feedback">
                    {{$parent.lang.required_field}}
                  </b-form-invalid-feedback>
              </div>
              <div class="text-start pa-3 pt-0">
                <label for="input-live">{{$parent.lang.password}}</label>
                  <b-form-input
                    id="password"
                    v-model="password"
                    @keyup.enter="enterlogin()"
                    aria-describedby="input-live-help input-live-feedback"
                    :placeholder="$parent.lang.password"
                    @keyup.native.enter="doLogin()"
                    type="password"
                    trim
                  ></b-form-input>
                  <b-form-invalid-feedback v-if="!passState" id="input-live-feedback">
                    {{$parent.lang.required_field}}
                  </b-form-invalid-feedback>
              </div>
              <div class="text-start pa-3 pt-0" style="direction:rtl">
                  <button class="btn btn-success" id="arabic" @click="changeLang('ar')" style="border:1px solid #000;background:#d7b100;width:50%;border-radius:0;color:#FFF !mportant;font-weight:bolder">عربي</button>
                  <button class="btn btn-success" id="english" @click="changeLang('en')" style="border:1px solid #000;background:#eee;color:#000;width:50%;border-radius:0;font-weight:bolder">English</button>
              </div>
              <div style="margin:20px 5px">
                <b-button @click="doLogin()" type="button" class="bgbalck" style="font-size: small;width:50%;background:#d7b100 !important;color:#000 !important;font-weight:bolder"> {{loginname}}&nbsp;	&nbsp;	<i class="fas fa-sign-in-alt"></i></b-button>
              </div>
              
            </div>
            <div class="text-center" style="color:black">SAMCOTEC V {{$store.state.Version}}</div>
        </div>
        </v-col>
        <v-col style="background:#fff;color:#000;height:100vh;margin-top:-3px;border-bottom:3px solid #d7b100;border-top:3px solid #d7b100;border-right:3px solid #000;border-left:3px solid #d7b100" cols="12" md="6">
          <div class="pa-5 mt-16 text-center" style="padding-top:25% !important">
            <img :src="`../img/samcotec-logo.png`" v-if="!$store.state.licenseType.cars" style="width:150;">
            <img :src="`../img/128-128-logo.png`" v-else style="width:150;">
          </div>
        </v-col>
    </v-row>
    <showMessage :message="SamMessage" :clientStatus="clientStatus" v-if="showStartUpMessage" />
  </div>
</template>

<script>
import axios from 'axios'
import showMessage from '@/components/showMessage'
export default {
  components: {showMessage},
  data() {
    return {
      showStartUpMessage: false,
      showerr: false,
      username: '',
      password: '',
      loginname: 'تسجيل دخول',
      SamMessage: '',
      clientStatus: '',
    }
  },
  computed: {
    nameState() {
      return this.username.length > 2 ? true : false
    },
    passState() {
      return this.password.length > 2 ? true : false
    },
  },
  created() {
    setTimeout(() => {
      this.changeLang(this.$store.state.deflang);
    },1000);
    //document.addEventListener('keypress', this.enterlogin);
  },
  methods: {
    changeLang(id) {
      if(id != "ar" && id != "en"){
        id = "ar";
      }
      if(id == "ar"){
        document.getElementById('arabic').style.background = "#d7b100";
        document.getElementById('arabic').style.color = "#000";
        document.getElementById('english').style.background = "#eee";
        document.getElementById('english').style.color = "#000";
      }else{
        document.getElementById('english').style.background = "#d7b100";
        document.getElementById('english').style.color = "#000";
        document.getElementById('arabic').style.background = "#eee";
        document.getElementById('arabic').style.color = "#000";
      }
      this.$store.state.deflang = id
      this.$cookies.set(this.$store.state.LangCooki, id)
      if(id == 'en'){
        this.$parent.lang = this.$store.state.lang.en;
        this.$parent.lang.dir = "ltr";
        this.$parent.lang.ldir = "rtl";
        this.$parent.lang.login_name = "Username";
        this.$parent.lang.password = "Password";
        this.$parent.lang.save_login_info = "Save login info";
        this.loginname = "Login"
      }else{
        this.$parent.lang = this.$store.state.lang.ar;
        this.$parent.lang.dir = "rtl";
        this.$parent.lang.ldir = "ltr";
        this.$parent.lang.login_name = "اسم الدخول";
        this.$parent.lang.password = "كلمة المرور";
        this.$parent.lang.save_login_info = "حفظ بيانات الدخول";
        this.loginname = "تسجيل الدخول"
      }
    },
    enterlogin(){
      // e.preventDefault();
      // if (event.keyCode == 13){
        // if(this.username == '') {
        //   document.getElementById('username').focus();
        //   return false;
        // }
        // if(this.password == '' && this.username != '') {
        //   document.getElementById('password').focus();
        //   return false;
        // }
        // this.doLogin();
     
    },
    doLogin(){

      let pp = new FormData();
      let type = 'register';

      pp.append('data[username]',this.username);
      pp.append('data[password]',this.password);
      pp.append('type',type);

      axios.post(
          this.$store.state.SAMCOTEC.r_path
          ,pp
      ).then((response) => {
          
          let res = response.data;
          // console.log(res);
          if(res.error.number == 200){
              this.$store.state.userinfo = res.results.data;
              let code = res.results.data.ID+'||'+res.results.hash+'||'+this.ip+'||'+res.results.time+'||'+this.$cookies.get(this.$store.state.LangCooki);
              this.$cookies.set(this.$store.state.COOKIEPhase,code,86400);
              //document.getElementById('HelloID').innerHTML = this.full_name
              this.$store.state.group_id = res.results.data.group_id;
              // if(res.results.data.group_id != 4)

              // else
                // this.$router.push({path: '/vendors/vendor-index/'})
              //window.location.href = '/home'
            if(res.results.data.message.showNumber >= 0 && res.results.data.message.showNumber < 4){
              this.showStartUpMessage = true;
              this.SamMessage = res.results.data.message.message;
              this.clientStatus = res.results.data.message.clientStatus;
            }
            else{
              this.$router.push({name: 'Home'})
            }
            // console.log(res.results.data.message.showNumber);
            //
          }else{
            this.showerr = true;
            // this.errorMessage = response.data.error.number+': '+response.data.error.message;
          }
      });
    }
  },
}
</script>
<style>
.body{
  border:3px inset #000;
  background:rgb(215, 177,0) !important;
  color:#FFF;
  height: 100vh;
}
.bgbalck{
  background:rgb(0,0,0) !important;
  color:#FFF !important;
}
.errorMesg{
  background:rgba(0, 0, 0,0.5);
  padding:5px;
  width:50%;
  margin:auto;
  border-radius: .8rem;
}
</style>